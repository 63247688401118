<template>
  <section>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title"><b>รายละเอียดลูกค้า : </b> {{this.customerData.custNo}} {{this.customerData.custTitle === 'ไม่ระบุ' ? '' : this.customerData.custTitle}}  {{this.customerData.custName}} {{this.customerData.custSurname}}  เบอร์โทร : {{this.customerData.custTel}}  มือถือ : {{this.customerData.custMobile}}</h3>
        <div class="card-tools">
          <div class="input-group input-group-sm">
            <button class="btn btn-sm btn-warning ml-2" @click.prevent="backPage()"  style=""><i class="fas fa-backward fa-fw"></i>ย้อนกลับ</button>
            <button class="btn btn-sm btn-success ml-2" @click.prevent="getResults()"  style=""><i class="fas fa-sync-alt fa-fw"></i></button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row mt-2">
          <div class="col">
            <h5 class="float-left">ข้อมูลสายตา : {{this.data ? this.data.data.length : 0}} รายการ</h5>
            <button class="btn btn-sm btn-primary float-right" @click.prevent="createModal()"  style=""><i class="fas fa-plus fa-fw fa-fw"></i>เพิ่มรายการใหม่ </button>
          </div>
          <div class="card-body table-responsive p-0 mt-2" style="height: 300px;">
            <table class="table table-head-fixed table-hover table-bordered">
              <thead class="thead-light text-center">
              <tr>
                <!--                <th scope="col">#</th>-->
                <th scope="col" style="background-color: rgb(197,196,196)">No</th>
                <th scope="col" style="background-color: rgb(197,196,196)">Config</th>
                <th v-for="ths in th" :key="ths" style="background-color:rgb(197,196,196)">
                  {{ths}}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(datas,index) in data.data" :key="datas.id">
                <th scope="row" style="width: 5px;font-size: 12px" >{{index+1}}</th>
                <td style="max-width:100%;white-space:nowrap;font-size: 12px">
                  <ul style="list-style: none;margin-left: -55px" >
                    <li ><a href="" @click.prevent="editModal(datas,index)"><i class="fas fa-caret-right fa-fw"></i>แก้ไข</a></li>
                    <li ><a href="" @click.prevent="cloneModal(datas,index)"><i class="fas fa-caret-right fa-fw"></i>ทำซ้ำ</a></li>
                    <li ><a href="" @click.prevent="deleteModal(datas,index)"><i class="fas fa-caret-right fa-fw" ></i>ลบ</a></li>
                  </ul>
                </td>
                <td style="max-width:100%;white-space:nowrap;font-size: 12px">
                  <template>
                    <ul style="margin-left: -35px">
                      <li>ล่าสุด : {{datas.sightDate | myDateThshort}}</li>
                      <li>วันนัด : {{datas.sightAppoint | myDateThshort}}</li>
                    </ul>
                  </template>
                </td>
                <td style="font-size: 12px">{{datas.sightRSPH }}</td>
                <td style="font-size: 12px">{{datas.sightRCYL }}</td>
                <td style="font-size: 12px">{{datas.sightRAXIS }}</td>
                <td style="font-size: 12px">{{datas.sightRADD }}</td>
                <td style="font-size: 12px">{{datas.sightRVA }}</td>
                <td style="font-size: 12px">{{datas.sightRPRISM }}</td>
                <td style="font-size: 12px">{{datas.sightRBASE }}</td>
                <td style="font-size: 12px">{{datas.sightLSPH }}</td>
                <td style="font-size: 12px">{{datas.sightLCYL }}</td>
                <td style="font-size: 12px">{{datas.sightLAXIS }}</td>
                <td style="font-size: 12px">{{datas.sightLADD }}</td>
                <td style="font-size: 12px">{{datas.sightLVA }}</td>
                <td style="font-size: 12px">{{datas.sightLPRISM }}</td>
                <td style="font-size: 12px">{{datas.sightLBASE }}</td>
                <td style="max-width:100%;white-space:nowrap;font-size: 12px">
                  <ul style="list-style: none;margin-left: -35px" >
                    <li >PD ไกล : {{datas.sightPD ? datas.sightPD : '-'}}</li>
                    <li >PD ใกล้ : {{datas.sightPDL ? datas.sightPDL : '-'}}</li>
                  </ul>
                </td>
                <td style="font-size: 12px">{{datas.sightSEGSH }}</td>
                <td style="font-size: 12px"><pre>{{datas.sightComment }}</pre></td>
              </tr>
              <tr v-show="!this.data.data"><td colspan="20" class="text-center">ไม่มีรายการ</td></tr>

              </tbody>
            </table>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <h5>ข้อมูลอุปกรณ์</h5>
          </div>
          <div class="card-body table-responsive p-0" style="height: 300px;">
            <table class="table table-head-fixed table-hover table-bordered">
              <thead class="thead-light text-center">
              <tr>
                <!--                <th scope="col">#</th>-->
                <th scope="col" style="background-color: rgb(197,196,196)">No</th>
                <th scope="col" style="background-color: rgb(197,196,196)">Config</th>
                <th v-for="ths in th2" :key="ths" style="background-color: rgb(197,196,196)">
                  {{ths}}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(datas,index) in data.data" :key="datas.id">
                <th scope="row" style="width: 5px;font-size: 12px" >{{index+1}}</th>
                <td style="max-width:100%;white-space:nowrap;font-size: 12px">
                  <ul style="list-style: none;margin-left: -60px" >
                    <li ><a href="" @click.prevent="editModal(datas,index)"><i class="fas fa-caret-right fa-fw"></i>แก้ไข</a></li>
                    <li ><a href="" @click.prevent="deleteModal(datas,index)"><i class="fas fa-caret-right fa-fw" ></i>ลบ</a></li>
                  </ul>
                </td>
                <td style="width: 7%;font-size: 12px">{{datas.sightDate | myDateThshort}}</td>
                <td style="font-size: 12px">{{datas.lensSingle }}</td>
                <td style="font-size: 12px">{{datas.lensBifocal }}</td>
                <td style="font-size: 12px">{{datas.lensProgress }}</td>
                <td style="font-size: 12px">{{datas.lensLensType }}</td>
                <td style="font-size: 12px">{{datas.lensColor }}</td>
                <td style="font-size: 12px">{{datas.lensFrame }}</td>
                <td style="font-size: 12px">{{datas.contactLens }}</td>
                <td style="font-size: 12px">{{datas.lensNote }}</td>
                <td style="font-size: 12px">{{datas.lensType }}</td>
<!--                <td style="font-size: 12px">{{datas.sightFrequency }}</td>-->
              </tr>
              <tr v-show="!this.data"><td colspan="12" class="text-center">ไม่มีรายการ</td></tr>

              </tbody>
            </table>
          </div>
        </div>


      </div>
      <div class="card-footer">

      </div>

    </div>
    <!-- /.card -->
    <b-modal modal-class="modal-fullscreen"  id="modal-create" ref="modal"
             @ok="createData"
             title="เพิ่มรายการใหม่">
      <form ref="form" @submit.prevent="createData">
        <div class="row">
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>วันทำรายการ</label>
            </div>
            <input
                v-model.trim="$v.form.sightDate.$model"
                type="date"
                class="form-control"
                placeholder="Username"
                :class="{ 'is-invalid':$v.form.sightDate.$error }"
            />
            <span v-if="!$v.form.sightDate.required " class="invalid-feedback">required</span>
          </div>
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>วันนัดหมายครั้งถัดไป</label>
            </div>
            <input
                v-model.trim="$v.form.sightAppoint.$model"
                type="date"
                class="form-control"
                placeholder="Username"
                :class="{ 'is-invalid':$v.form.sightAppoint.$error }"
            />
            <span v-if="!$v.form.sightAppoint.required " class="invalid-feedback">required</span>
          </div>
        </div>
        <div class="row ">
          <div class="form-group col-md-2 col-sm-1 ">
            <div class="col">
              <label>สายตาข้างขวา :</label>
            </div>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>SPH</label>
            </div>
            <input v-model="form.sightRSPH" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>CYL</label>
            </div>
            <input v-model="form.sightRCYL" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>AXIS</label>
            </div>
            <input v-model="form.sightRAXIS" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>ADD</label>
            </div>
            <input v-model="form.sightRADD" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>VA</label>
            </div>
            <input v-model="form.sightRVA" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>PRISM</label>
            </div>
            <input v-model="form.sightRPRISM" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>BASE</label>
            </div>
            <input v-model="form.sightRBASE" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>สายตาข้างซ้าย :</label>
            </div>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>SPH</label>
            </div>
            <input v-model="form.sightLSPH" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>CYL</label>
            </div>
            <input v-model="form.sightLCYL" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>AXIS</label>
            </div>
            <input v-model="form.sightLAXIS" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>ADD</label>
            </div>
            <input v-model="form.sightLADD" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>VA</label>
            </div>
            <input v-model="form.sightLVA" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>PRISM</label>
            </div>
            <input v-model="form.sightLPRISM" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>BASE</label>
            </div>
            <input v-model="form.sightLBASE" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12 col-sm-1">
              <div class="col text-center">
                <label>Comment</label>
              </div>
              <textarea v-model="form.sightComment" class="form-control" rows="3"></textarea>
            </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>PD ไกล</label>
            </div>
            <input v-model="form.sightPD" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>PD ใกล้</label>
            </div>
            <input v-model="form.sightPDL" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>Seg.H</label>
            </div>
            <input v-model="form.sightSEGSH" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>Single</label>
            </div>
            <input v-model="form.lensSingle" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>Bifocal</label>
            </div>
            <input v-model="form.lensBifocal" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>Progress</label>
            </div>
            <input v-model="form.lensProgress" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-10 col-sm-1">
            <div class="col">
              <label>เลนส์</label>
            </div>
            <input v-model="form.lensLensType" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>สี</label>
            </div>
            <input v-model="form.lensColor" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12 col-sm-1">
            <div class="col">
              <label>กรอบ</label>
            </div>
            <input v-model="form.lensFrame" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12 col-sm-1">
            <div class="col">
              <label>Contact lens</label>
            </div>
            <input v-model="form.contactLens" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>ประเภท</label>
            </div>
            <input v-model="form.lensType" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-10 col-sm-1">
            <div class="col">
              <label>หมายเหตุ</label>
            </div>
            <input v-model="form.lensNote" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal modal-class="modal-fullscreen" id="modal-update" ref="modal"
             @ok="updateData"
             title="แก้ไขรายการ">
      <form ref="form" @submit.prevent="updateData">
        <div class="row">
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>วันทำรายการ</label>
            </div>
            <input
                v-model.trim="$v.form.sightDate.$model"
                type="date"
                class="form-control"
                placeholder="Username"
                :class="{ 'is-invalid':$v.form.sightDate.$error }"
            />
            <span v-if="!$v.form.sightDate.required " class="invalid-feedback">required</span>
          </div>
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>วันนัดหมายครั้งถัดไป</label>
            </div>
            <input
                v-model.trim="$v.form.sightAppoint.$model"
                type="date"
                class="form-control"
                placeholder="Username"
                :class="{ 'is-invalid':$v.form.sightAppoint.$error }"
            />
            <span v-if="!$v.form.sightAppoint.required " class="invalid-feedback">required</span>
          </div>
        </div>
        <div class="row ">
          <div class="form-group col-md-2 col-sm-1 ">
            <div class="col">
              <label>สายตาข้างขวา :</label>
            </div>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>SPH</label>
            </div>
            <input v-model="form.sightRSPH" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>CYL</label>
            </div>
            <input v-model="form.sightRCYL" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>AXIS</label>
            </div>
            <input v-model="form.sightRAXIS" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>ADD</label>
            </div>
            <input v-model="form.sightRADD" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>VA</label>
            </div>
            <input v-model="form.sightRVA" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>PRISM</label>
            </div>
            <input v-model="form.sightRPRISM" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>BASE</label>
            </div>
            <input v-model="form.sightRBASE" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>สายตาข้างซ้าย :</label>
            </div>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>SPH</label>
            </div>
            <input v-model="form.sightLSPH" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>CYL</label>
            </div>
            <input v-model="form.sightLCYL" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>AXIS</label>
            </div>
            <input v-model="form.sightLAXIS" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>ADD</label>
            </div>
            <input v-model="form.sightLADD" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>VA</label>
            </div>
            <input v-model="form.sightLVA" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>PRISM</label>
            </div>
            <input v-model="form.sightLPRISM" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>BASE</label>
            </div>
            <input v-model="form.sightLBASE" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12 col-sm-1">
            <div class="col text-center">
              <label>Comment</label>
            </div>
            <textarea v-model="form.sightComment" class="form-control" rows="3"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>PD ไกล</label>
            </div>
            <input v-model="form.sightPD" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>PD ใกล้</label>
            </div>
            <input v-model="form.sightPDL" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>Seg.H</label>
            </div>
            <input v-model="form.sightSEGSH" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>Single</label>
            </div>
            <input v-model="form.lensSingle" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>Bifocal</label>
            </div>
            <input v-model="form.lensBifocal" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>Progress</label>
            </div>
            <input v-model="form.lensProgress" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-10 col-sm-1">
            <div class="col">
              <label>เลนส์</label>
            </div>
            <input v-model="form.lensLensType" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>สี</label>
            </div>
            <input v-model="form.lensColor" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12 col-sm-1">
            <div class="col">
              <label>กรอบ</label>
            </div>
            <input v-model="form.lensFrame" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12 col-sm-1">
            <div class="col">
              <label>Contact lens</label>
            </div>
            <input v-model="form.contactLens" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>ประเภท</label>
            </div>
            <input v-model="form.lensType" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-10 col-sm-1">
            <div class="col">
              <label>หมายเหตุ</label>
            </div>
            <input v-model="form.lensNote" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal modal-class="modal-fullscreen" id="modal-clone" ref="modal"
             @ok="cloneData"
             title="เพิ่มรายการใหม่ จากข้อมูลเดิม">
      <form ref="form" @submit.prevent="cloneData">
        <div class="row">
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>วันทำรายการ</label>
            </div>
            <input
                v-model.trim="$v.form.sightDate.$model"
                type="date"
                class="form-control"
                placeholder="Username"
                :class="{ 'is-invalid':$v.form.sightDate.$error }"
            />
            <span v-if="!$v.form.sightDate.required " class="invalid-feedback">required</span>
          </div>
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>วันนัดหมายครั้งถัดไป</label>
            </div>
            <input
                v-model.trim="$v.form.sightAppoint.$model"
                type="date"
                class="form-control"
                placeholder="Username"
                :class="{ 'is-invalid':$v.form.sightAppoint.$error }"
            />
            <span v-if="!$v.form.sightAppoint.required " class="invalid-feedback">required</span>
          </div>
        </div>
        <div class="row ">
          <div class="form-group col-md-2 col-sm-1 ">
            <div class="col">
              <label>สายตาข้างขวา :</label>
            </div>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>SPH</label>
            </div>
            <input v-model="form.sightRSPH" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>CYL</label>
            </div>
            <input v-model="form.sightRCYL" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>AXIS</label>
            </div>
            <input v-model="form.sightRAXIS" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>ADD</label>
            </div>
            <input v-model="form.sightRADD" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>VA</label>
            </div>
            <input v-model="form.sightRVA" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>PRISM</label>
            </div>
            <input v-model="form.sightRPRISM" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>BASE</label>
            </div>
            <input v-model="form.sightRBASE" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>สายตาข้างซ้าย :</label>
            </div>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>SPH</label>
            </div>
            <input v-model="form.sightLSPH" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>CYL</label>
            </div>
            <input v-model="form.sightLCYL" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>AXIS</label>
            </div>
            <input v-model="form.sightLAXIS" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>ADD</label>
            </div>
            <input v-model="form.sightLADD" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>VA</label>
            </div>
            <input v-model="form.sightLVA" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>PRISM</label>
            </div>
            <input v-model="form.sightLPRISM" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col col-sm-1">
            <div class="col">
              <label>BASE</label>
            </div>
            <input v-model="form.sightLBASE" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12 col-sm-1">
            <div class="col text-center">
              <label>Comment</label>
            </div>
            <textarea v-model="form.sightComment" class="form-control" rows="3"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>PD ไกล</label>
            </div>
            <input v-model="form.sightPD" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>PD ใกล้</label>
            </div>
            <input v-model="form.sightPDL" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>Seg.H</label>
            </div>
            <input v-model="form.sightSEGSH" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>Single</label>
            </div>
            <input v-model="form.lensSingle" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>Bifocal</label>
            </div>
            <input v-model="form.lensBifocal" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>Progress</label>
            </div>
            <input v-model="form.lensProgress" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-10 col-sm-1">
            <div class="col">
              <label>เลนส์</label>
            </div>
            <input v-model="form.lensLensType" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>สี</label>
            </div>
            <input v-model="form.lensColor" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12 col-sm-1">
            <div class="col">
              <label>กรอบ</label>
            </div>
            <input v-model="form.lensFrame" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12 col-sm-1">
            <div class="col">
              <label>Contact lens</label>
            </div>
            <input v-model="form.contactLens" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>ประเภท</label>
            </div>
            <input v-model="form.lensType" type="text" class="form-control" placeholder=""/>
          </div>
          <div class="form-group col-md-10 col-sm-1">
            <div class="col">
              <label>หมายเหตุ</label>
            </div>
            <input v-model="form.lensNote" type="text" class="form-control" placeholder=""/>
          </div>
        </div>
      </form>
    </b-modal>

  </section>
</template>

<script>
import router from "@/router";
import { Form } from 'vform';
import {mapGetters,mapMutations} from "vuex";
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import moment from "moment/moment";

export default {
  name: 'customerdetail',
  data(){
    return{
      data:'',
      customerData:'',
      th:['วันที่','R_SPH','R_CYL','R_AXIS','R_ADD','R_VA','R_PRISM','R_BASE',
        'L_SPH','L_CYL','L_AXIS','L_ADD','L_VA','L_PRISM','L_BASE','PD','Segs.H','comment'],
      th2:['วันที่','Single','Bifocal','Progress','เลนส์','สี','กรอบ','contact lens','หมายเหตุ','ประเภท'],
      page: 1,
      count: 0,
      pageSize: 0,
      company: '',
      check: 0,

      form : new Form ({
        singId:'',
        custId : '',
        userId : '',
        sightDate : '',
        sightAppoint : '',
        sightRSPH : '',
        sightRCYL : '',
        sightRAXIS : '',
        sightRADD : '',
        sightRVA : '',
        sightRPRISM : '',
        sightRBASE : '',
        sightLSPH : '',
        sightLCYL : '',
        sightLAXIS : '',
        sightLADD : '',
        sightLVA : '',
        sightLPRISM : '',
        sightLBASE : '',
        sightPD : '',
        sightPDL : '',
        sightSEGSH : '',
        lensSingle : '',
        lensBifocal : '',
        lensProgress : '',
        lensLensType : '',
        lensColor : '',
        lensFrame : '',
        lensNote : '',
        lensType : '',
        sightFrequency : '',
        contactLens : '',
        sightComment : '',
      })

    }
  },
  validations: {
    form : {
      sightDate: {
        required,
      },
      sightAppoint: {
        required,
      },
    }

  },

  computed: {
    ...mapGetters(["custData"]),

  },

  mounted() {
    this.getResults()
  },
  methods:{
    ...mapMutations(['clear_custdata']),
    async getResults(){
       this.customerData = this.custData
      await axios.put('api/customer/customerSightIndex/'+this.customerData.custId)
          .then( async response => {
            if (response.data.success === true ){
              this.data = response.data
              this.count = response.data.totalItems;
              this.pageSize = response.data.limit;
            }
          }).catch((error) => {
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
            }
          });
    },
    toast(msg){
      this.$swal({
        position: 'top-end',
        icon: 'success',
        toast: true,
        title: msg,
        showConfirmButton: false,
        timer: 1500
      })
    },
    async deleteModal(data,index){
      this.$swal({
        title: 'Are you sure?',
        text: "ท่านต้องการลบข้อมูลใช่หรือไม่!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then( async (result) => {
        if (result.value) {
          await axios.delete('api/customer/customerSightDelete/'+data.singId)
              .then(async(res) => {
                if (!res.data.success === true){
                  this.$swal({
                    type: 'error',
                    title: 'ข้อมูลไม่ถูกต้อง',
                    text: 'ข้อมูลไม่ถูกต้อง',
                    showConfirmButton: true,
                    timer: 5000
                  })
                }else {
                  await this.data.data.splice(index,1)
                  this.$swal(
                      'Deleted!',
                      'Your file has been deleted.',
                      'success'
                  )
                }
              })
        }
      }).catch((err) => {
        this.$swal("Failed!", "There was something wronge", "warning");
        console.log(err)
      })
    },

    async backPage(){
      await this.clear_custdata()
      router.push('/customers')
    },

    createModal(){
      this.$v.$reset();
      this.form.reset()
      this.form.sightDate =  moment().format("YYYY-MM-DD")
      this.form.sightAppoint =  moment().add(1, 'years').format("YYYY-MM-DD")
      this.form.custId =  this.customerData.custId
      this.$bvModal.show('modal-create')
    },

    async createData(bvModalEvt){
        bvModalEvt.preventDefault()
        this.$v.$touch()
        if (this.$v.$pending || this.$v.$error) return;
        if (this.check === 0){
        this.check = 1
        await this.form.post('api/customer/createCustomerSight')
            .then( async response => {
              if (response.data.success === true ){
                await this.data.data.unshift(response.data.data)
                this.$bvModal.hide('modal-create')
              }
                this.check = 0
            }).catch((error) => {
              if (error.response.status === 500) {
                this.check = 0
                this.$swal('something wrong', error.response.data.msg, 'error');
              }
              this.check = 0
            });
      }
    },

    editModal(data,index){
      this.$v.$reset();
      this.Index = index;
      this.form.fill(data);
      this.$bvModal.show('modal-update')
    },

    async updateData(bvModalEvt){
      bvModalEvt.preventDefault()
      this.$v.$touch()
      if (this.$v.$pending || this.$v.$error) return;
      await this.form.put('api/customer/updateCustomerSight/'+this.form.singId)
          .then( async response => {
            if (response.data.success === true ){
              await this.data.data.splice(this.Index,1,response.data.data)
              this.$bvModal.hide('modal-update')
            }
            this.check = 0
          }).catch((error) => {
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
            }
            this.check = 0
          });
    },

    cloneModal(data){
      this.$v.$reset();
      this.form.fill(data);
      this.form.sightDate =  moment().format("YYYY-MM-DD")
      this.form.sightAppoint =  moment().add(1, 'years').format("YYYY-MM-DD")
      this.form.custId =  this.customerData.custId
      this.form.sightAppoint =  ''
      this.form.lensSingle =  ''
      this.form.lensBifocal =  ''
      this.form.lensProgress =  ''
      this.form.lensLensType =  ''
      this.form.lensColor =  ''
      this.form.lensFrame =  ''
      this.form.lensType =  ''
      this.form.lensNote =  ''
      this.form.contactLens =  ''
      this.$bvModal.show('modal-clone')
    },

    async cloneData(bvModalEvt){
      bvModalEvt.preventDefault()
      this.$v.$touch()
      if (this.$v.$pending || this.$v.$error) return;
      if (this.check === 0) {
        this.check = 1
        await this.form.post('api/customer/createCustomerSight')
            .then( async response => {
              if (response.data.success === true ){
                await this.data.data.unshift(response.data.data)
                this.$bvModal.hide('modal-clone')
              }
            }).catch((error) => {
              if (error.response.status === 500) {
                this.$swal('something wrong', error.response.data.msg, 'error');
              }
            });
      }

    },
  }
}
</script>
<style>
.modal-fullscreen .modal-dialog {
  max-width: 90%;
  /*margin: 0;*/
  /*top: 0;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*right: 0;*/
  /*height: 100vh;*/
  /*display: flex;*/
  /*position: fixed;*/
  /*z-index: 100000;*/
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
}
</style>
